import styles from './style.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom';
import apiSocket from '../../services/socket';
import accountIcon from '../../assets/accountIcon.svg';
import { useAuth } from '../../contexts/AuthContext';
import { getProfile } from '../../services/api';
import { useEffect, useState } from 'react';
import moment from 'moment';
import getStripe from '../../services/stripe';
import Modal from 'react-modal';
import { FaArrowLeftLong } from 'react-icons/fa6';

export default function Account() {
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();
  const { jwt } = useAuth();
  const [ user, setUser ] = useState(null);
  const [ modal, setModal ] = useState(null);

  async function handleCheckout() {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: process.env.REACT_APP_STRIPE_PRICE_ID,
          quantity: 1,
        },
      ],
      mode: 'payment',
      successUrl: `${process.env.REACT_APP_APP_URL}/account?success=true`,
      cancelUrl: `${process.env.REACT_APP_APP_URL}/account?success=false`,
      customerEmail: user.email,
    });
    console.warn(error.message);
  }

  const fetchUser = async () => {
    if (!jwt) return;
    const user = await getProfile(jwt);
    setUser(user);
  }

  useEffect(() => {
    if (searchParams.get('success') === 'true') {
      setModal('Votre paiement a bien été accepté !');
    } else if (searchParams.get('success') === 'false') {
      setModal('Votre paiement n\'a pas pu être traité avec succès.');
    }
  }, [ searchParams ]);

  useEffect(() => {
    fetchUser();
  }, []);

  if (!user) return null;
  return (
    <div className={styles.container}>
      <div className={styles.backContainer} onClick={() => navigate(-1)}>
        <FaArrowLeftLong className={styles.arrow}/>
        <span className={styles.backText}>Retour</span>
      </div>
      <h1 className={styles.title}>Bienvenue {user.firstname} {user.lastname}</h1>
      <h2 className={styles.amount}>Mon solde : {Math.round(user.seconds / 60)} minutes</h2>
      <input type={'submit'} onClick={handleCheckout} value={'Acheter des minutes'}/>
      <h2 className={styles.subtitle}>Mes dernières sessions</h2>
      <ul className={styles.listContainer}>
        {user.userSessions.map((userSession) => (
          <li className={styles.list} key={userSession.id}>{moment.duration(moment(userSession.finishedAt).diff(moment(userSession.createdAt))).humanize()}, le {moment(userSession.createdAt).format('DD/MM/YYYY à HH:mm')}</li>
        ))}
      </ul>
      <Modal
        overlayClassName={styles.overlay}
        className={styles.modal}
        isOpen={!!modal}
        onRequestClose={() => setModal(null)}
      >
        <p className={styles.modalText}>{modal}</p>
      </Modal>
    </div>
  )
}
