import Select from 'react-select';
import { useEffect, useState } from 'react';
import styles from './InputSelect.module.scss';
import {isMobile} from 'react-device-detect';

export default ({ title, onDeviceSelected, onOutputDeviceSelected, onLanguageSelected, showLanguageSelect, mode, isGuest }) => {
  const [ devices, setDevices ] = useState([]);

  const getDevices = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    let devices = await navigator.mediaDevices.enumerateDevices();
    console.log('devices', devices);
    setDevices(devices.filter(d => d.kind === 'audioinput' && d.deviceId !== 'default').map(d => ({
      value: d.deviceId,
      label: d.label,
    })))
  };

  const [ outputDevices, setOutputDevices ] = useState([]);

  const getOutputDevices = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    let devices = await navigator.mediaDevices.enumerateDevices();
    console.log('devices', devices);
    setOutputDevices(devices.filter(d => d.kind === 'audiooutput' && d.deviceId !== 'default').map(d => ({
      value: d.deviceId,
      label: d.label,
    })))
  };

  const languages = [
    { value: 'fr', label: 'Français' },
    { value: 'en', label: 'English' },
    { value: 'de', label: 'Deutsch' },
    { value: 'es', label: 'Español' },
    { value: 'it', label: 'Italiano' },
    { value: 'pt', label: 'Portugues' },
    { value: 'pl', label: 'Polish' },
  ];

  useEffect(() => {
    if (!isGuest) {
      getDevices();
    }
    if (!isMobile) {
      getOutputDevices();
    }
  }, [ isGuest ]);

  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      {!isMobile && <Select
        className={styles.select}
        onChange={({ value }) => {
          onOutputDeviceSelected(value);
        }}
        defaultValue={outputDevices[0]}
        options={outputDevices}
        placeholder={'Output device'}
      />}
      {!isMobile && !isGuest && <Select
        className={styles.select}
        onChange={({ value }) => {
          onDeviceSelected(value);
        }}
        defaultValue={devices[0]}
        options={devices}
        placeholder={'Input device'}
      />}
      {showLanguageSelect && <Select
        className={styles.select}
        onChange={({ value }) => {
          onLanguageSelected(value);
        }}
        options={languages}
        placeholder={'Language'}
      />}
    </div>
  );
};
