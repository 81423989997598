export default [
  { "content": "CIRCAL 75" },
  { "content": "CIRCAL 100" },
  { "content": "D" },
  { "content": "pire" },
  { "content": "ESPACIO NEXT" },
  { "content": "TECHNAL" },
  { "content": "HUECK" },
  { "content": "PT" },
  { "content": "Extrusion Europe and America" },
  { "content": "TENTAL" },
  { "content": "WIC Tech NG" },
  { "content": "WIC Line NG" },
  { "content": "SOLEAL Next" },
 ];
