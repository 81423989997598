import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import App from '../App';
import Meeting from './Meeting';
import Login from './auth/Login';
import Register from './auth/Register';
import { useAuth } from '../contexts/AuthContext';
import { useEffect } from 'react';
import Home from './Home';
import Account from './Account';
import { getProfile } from '../services/api';

const ProtectedRoute = ({ children }) => {
  const { jwt, loaded, setUser, setJwt, setIsGuest } = useAuth();
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();

  const loadUser = async () => {
    if (!loaded || !jwt) return;
    const user = await getProfile(jwt);
    if (!user) setJwt(null);
    setUser(user);
  }

  useEffect(() => {
    if (searchParams.get('guest') === 'true') {
      setIsGuest(searchParams.get('guest') === 'true');
    } else {
      if (loaded && !jwt) navigate('/login', { replace: true });
      loadUser();
    }
  }, [ jwt, loaded, searchParams ]);

  return children;
}

export default function Router() {
  return (
    <Routes>
      <Route path={'/'} element={<ProtectedRoute children={<Home/>}/>}/>
      <Route path={'/login'} element={<Login/>}/>
      <Route path={'/register'} element={<Register />}/>
      <Route path={'/meetings'} element={<ProtectedRoute children={<Meeting/>}/>}/>
      <Route path={'/account'} element={<ProtectedRoute children={<Account/>}/>}/>
    </Routes>
  );
};
