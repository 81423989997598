import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./Subtitles.module.scss";
import { useAuth } from "../../contexts/AuthContext";
import { ReactMic } from "../react-mic/src";
import apiSocket from "../../services/socket";
import words from "../../assets/words-circal";

import Papa from "papaparse";
const API_KEY = "aYOMAen6JmD8CAo06Ui70CnFRHB03OUb";

export default function Subtitles({ device, language, muted, isGuest, code }) {
  const [recognize, setRecognize] = useState(false);
  const [recording, setRecording] = useState(false);
  const { user } = useAuth();
  const userId = useMemo(() => user?.id, [user]);
  const endAnchor = useRef(null);
  const [result, setResult] = useState({ transcript: "", language: "" });
  const [newLine, setNewLine] = useState(null);
  const [lines, setLines] = useState([]);
  const [customDictionary, setCustomDictionary] = useState([]);
  const smSocket = useRef({});
  const buffer = useRef(new Blob());

  const processCSVData = (csvData) => {
    Papa.parse(csvData, {
      header: false,
      skipEmptyLines: true,
      complete: (results) => {
        const speechmaticsDict = {};
        const headers = results.data[0]; // First row is headers
        const langPronunciationPairs = {};

        headers.forEach((header) => {
          const match = header.match(/language_(.*)/);
          if (match) {
            const langCode = match[1];
            langPronunciationPairs[langCode] = {
              language: header,
              pronunciation: `pronunciation_${langCode}`,
            };
          }
        });

        results.data.slice(1).forEach((row) => {
          Object.keys(langPronunciationPairs).forEach((langCode) => {
            const pair = langPronunciationPairs[langCode];

            // Check if the current language is present in the row
            if (row[headers.indexOf(pair.language)]) {
              if (!speechmaticsDict[langCode]) {
                speechmaticsDict[langCode] = [];
              }

              // Process pronunciations
              const pronunciations = row[headers.indexOf(pair.pronunciation)]
                ? row[headers.indexOf(pair.pronunciation)]
                    .split(",")
                    .map((p) => p.trim())
                : [];
              const res = {
                content: row[headers.indexOf(pair.language)].trim(),
              };
              if (pronunciations.length > 0) {
                res.sounds_like = pronunciations;
              }
              speechmaticsDict[langCode].push(res);
            }
          });
        });
        setCustomDictionary(speechmaticsDict[language] || []);
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const pushLine = useCallback(
    (text, userId, userName) => {
      const newLines = [...lines];
      if (lines.length > 0 && newLines[lines.length - 1].userId === userId) {
        newLines[lines.length - 1].text +=
          ([".", ","].includes(text[0]) ? "" : " ") + text;
      } else {
        newLines.push({
          text: `${userName} : ${text}`,
          userId,
        });
      }
      setLines(newLines.slice(-20));
    },
    [lines, setLines]
  );

  useEffect(() => {
    if (!newLine) return;
    pushLine(newLine.text, newLine.userId, newLine.userName);
  }, [newLine]);

  useEffect(() => {
    endAnchor.current?.scrollIntoView({ behavior: "smooth" });
  }, [lines]);

  const initSmSocket = async () => {
    try {
      const res = await fetch(
        "https://mp.speechmatics.com/v1/api_keys?type=rt",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
          body: JSON.stringify({
            ttl: 3600,
          }),
        }
      );
      const { key_value } = await res.json();

      console.log("key_value", key_value);
      smSocket.current = new WebSocket(
        `wss://eu2.rt.speechmatics.com/v2/${language}?jwt=${key_value}`
      );
      smSocket.current.onerror = (event) => {
        console.log("socket error", event);
      };
      smSocket.current.onclose = (event) => {
        console.log("socket closed", event);
        // setTimeout(() => window.location.reload(), 2000);
      };
      smSocket.current.onopen = (event) => {
        console.log("socket open", event);
        console.log("custom_dictionary", customDictionary);
        smSocket.current.send(
          JSON.stringify({
            message: "StartRecognition",
            audio_format: {
              type: "raw",
              encoding: "pcm_s16le",
              sample_rate: 48000,
            },
            transcription_config: {
              language: language,
              max_delay: 5,
              max_delay_mode: "flexible",
              additional_vocab: customDictionary,
            },
          })
        );
      };

      smSocket.current.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        if (data.message === "AddTranscript") {
          const { transcript } = data.metadata;
          if (transcript) {
            setResult({ transcript, language });
          }
        }
      });
    } catch (err) {
      console.log("Error initSmSocket", err);
    }
  };

  const isSocketOpen = (socket) => {
    return socket !== null && socket !== undefined && socket.readyState === 1;
  };

  useEffect(() => {
    if (language && !isGuest) {
      setRecording(true);
      apiSocket.emit("request-csv", code);
      apiSocket.on("csv-data", (csvData) => {
        if (csvData) {
          processCSVData(csvData);
        } else {
          initSmSocket();
        }
      });
    } else {
      setRecording(false);
    }
  }, [language]);

  // fetch(testCsv)
  //       .then((response) => response.text())
  //       .then((csvData) => {
  //         processCSVData(csvData, language);
  //       })
  //       .catch((error) => console.error("Error fetching CSV:", error));

  useEffect(() => {
    if (language && !isGuest) {
      initSmSocket();
    }
  }, [customDictionary]);

  useEffect(() => {
    apiSocket.on("messages:add", (data) => {
      setNewLine({
        text: data.text,
        userId: data.userId,
        userName: data.userName,
      });
    });
    return () => {
      apiSocket.removeListener("messages:add");
      return null;
    };
  }, []);

  const translateResult = async (result) => {
    const { transcript: text, language } = result;
    if (!text) return;

    apiSocket.emit("messages:add", { text });

    setNewLine({
      text,
      userId: user?.id || null,
      userName: user ? `${user?.firstname} ${user?.lastname}` : "Guest",
    });
  };

  useEffect(() => {
    translateResult(result);
  }, [result]);

  useEffect(() => {
    if (recognize) {
      recognizeData();
      setRecognize(false);
    }
  }, [recognize]);

  const onData = (data) => {
    buffer.current = new Blob([buffer.current, data]);
    if (buffer.current.size >= 16384) {
      setRecognize(true);
    }
  };

  const recognizeData = async () => {
    if (isSocketOpen(smSocket.current)) {
      const data = buffer.current;
      const chunk = data.slice(0, 16384);
      buffer.current = data.slice(16384);
      const rawData = await chunk.arrayBuffer();
      smSocket.current.send(rawData);
    }
  };

  return (
    <div className={styles.container}>
      {!isGuest && (
        <ReactMic
          record={recording && !muted}
          className={styles.reactMic}
          onData={onData}
          strokeColor="blue"
          backgroundColor="white"
          bufferSize={512}
          mimeType="audio/wav"
          deviceId={device || undefined}
          channelCount={2}
          echoCancellation={true}
          autoGainControl={true}
          noiseSuppression={true}
          sampleRate={48000}
        />
      )}
      {!!lines && lines.length > 0 && (
        <div className={styles.subtitles}>
          {lines.map((l, i) => (
            <p
              key={i}
              className={
                styles.text +
                " " +
                (l.userId === userId ? styles.color0 : styles.color0)
              }
            >
              {l.text}
            </p>
          ))}
          <div ref={endAnchor} />
        </div>
      )}
    </div>
  );
}
